$(function() {
  var alert_message = $("#alert").text();
  if(alert_message == "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account."){
    console.log('email-create');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'event' : 'registration',
    'authenticationMethod' : 'pre-email',
    });
  };
})